import { sourcesProvideIndex } from '@outmind/helpers';
import {
  DocumentIndiceNames,
  FileCategory,
  SearchByFieldOptions,
  SearchResult,
} from '@outmind/types';
import axios from 'axios';
import qs from 'qs';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { useSelector } from '../../store';
import { useConnectors } from '../connectors';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for retrieving the search results of the provided `index`
 */
export const useSearch = (
  index: DocumentIndiceNames,
  options?: searchOptions,
): UseSearchResponse => {
  const { data: connectors = [] } = useConnectors();

  const searchParams = useSearchParams(index, options);

  const sources = connectors.map((connector) => connector.source);
  const indexIsAvailable = sourcesProvideIndex(sources, index);

  const route = useApiRouteMaker(ApiQuery.SEARCH)();

  const searchQuery = useInfiniteQuery<SearchResponse>(
    [ApiQuery.SEARCH, searchParams],
    async ({ pageParam: page = 0 }) => {
      const response = await axios({
        method: route.method,
        params: { ...searchParams, page },
        paramsSerializer: (params) => qs.stringify(params),
        url: route.url,
        withCredentials: true,
      });

      return {
        ...response.data,
        nextPage: response.data.hasMore ? page + 1 : undefined,
      };
    },
    {
      enabled: options?.enabled ?? indexIsAvailable,
      getNextPageParam: ({ nextPage }) => nextPage,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...searchQuery,
    results: searchQuery.data?.pages.map((page) => page.results).flat() ?? [],
    total: Math.max(...(searchQuery.data?.pages.map((page) => page.total) ?? [0])),
  };
};

/**
 * Hook used to retrieve the search results of all the available indexes simultaneously
 */
export const useAllSearch = (): Record<DocumentIndiceNames, UseSearchResponse> => {
  const allIndices = Object.values(DocumentIndiceNames);

  return Object.fromEntries(allIndices.map((index) => [index, useSearch(index)])) as Record<
    DocumentIndiceNames,
    UseSearchResponse
  >;
};

/**
 * Hook used to retrieve the search parameters for the provided `index`
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearchParams = (index: DocumentIndiceNames, options?: searchOptions) => {
  const {
    connectors: connectorFilters,
    date,
    tabFilters: { fields, categories },
    folder,
    person,
    relatedDocuments,
  } = useSelector((s) => s.filters);
  const { chosenSortBy, q, queryId, mode } = useSelector((s) => s.search);

  const { data: connectors = [] } = useConnectors();

  const getConnectorIds = (): string[] => {
    const activeConnectors = connectors.filter((connector) => connectorFilters[connector.id]);
    return activeConnectors.length === 0
      ? connectors.map((connector) => connector.id)
      : activeConnectors.map((connector) => connector.id);
  };

  const getActiveCategories = (): FileCategory[] =>
    categories.filter((filter) => filter.isActive).map((filter) => filter.category);

  const getActiveFields = (): SearchByFieldOptions[] =>
    fields.filter((filter) => filter.isActive).map((filter) => filter.field);

  const connectorIds = getConnectorIds();

  return {
    byPerson: person?.email,
    byRelatedDocuments: relatedDocuments
      ? relatedDocuments.map(({ document, indexName }) => ({ id: document.id, indexName }))
      : undefined,
    categories: getActiveCategories(),
    clusterId: options?.clusterId,
    connectorIds,
    fields: getActiveFields(),
    from: date.from?.toISOString(),
    index,
    inFolderId: folder?.id,
    q,
    queryId,
    sortBy: mode === 'GET_LAST_ELEMENTS' ? 'date-desc' : chosenSortBy,
    to: date.to?.toISOString(),
  };
};

/**
 * Describes the output of the `useInfiniteQuery` hook
 */
interface SearchResponse {
  nextPage?: number;
  results: SearchResult[];
  total: number;
}

/**
 * Describes the output of the `useSearch` hook
 */
type UseSearchResponse = Omit<SearchResponse, 'nextPage'> & UseInfiniteQueryResult<SearchResponse>;

interface searchOptions {
  clusterId?: string;
  enabled?: boolean;
}
