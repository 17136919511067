import { Method } from 'axios';
import { useCallback } from 'react';

import { useUserProfile } from '../authn';

const API_URL = process.env['REACT_APP_API_URL'];

if (!API_URL) throw new Error('REACT_APP_API_URL is not defined');

/**
 * Hook used to retrieve a function that takes the route params as input and returns a Route
 */
export const useApiRouteMaker = (
  query: ApiQuery,
): ((params?: Record<string, string | number>) => Route) => {
  const { data: user } = useUserProfile();

  return useCallback(
    (params?: Record<string, string | number>) => getRouteMaker(query, user?.domainCode)(params),
    [query, user?.domainCode],
  );
};

/**
 * Describes all the different queries that can be performed with our API
 */
export enum ApiQuery {
  BIND_CONNECTOR = 'BIND_CONNECTOR',
  CREATE_CONNECTOR = 'CREATE_CONNECTOR',
  DELETE_SOURCE = 'DELETE_SOURCE',
  GET_AUTH_URL = 'GET_AUTH_URL',
  GET_CONNECTOR = 'GET_CONNECTOR',
  GET_CONNECTOR_ROOTS = 'GET_CONNECTOR_ROOTS',
  GET_CONNECTOR_SYNC_STATE = 'GET_CONNECTOR_SYNC_STATE',
  GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS',
  GET_FILE_PREVIEW = 'GET_FILE_PREVIEW',
  GET_LOCALDISK_CONNECTOR_STATE = 'GET_LOCALDISK_CONNECTOR_STATE',
  GET_MAIL_THREADS_PREVIEW = 'GET_MAIL_THREADS_PREVIEW',
  GET_MESSAGE_PREVIEW = 'GET_MESSAGE_PREVIEW',
  GET_MY_CONNECTORS = 'GET_MY_CONNECTORS',
  GET_MY_PROFILE = 'GET_MY_PROFILE',
  GET_NEW_PAYMENT_SESSION = 'GET_NEW_PAYMENT_SESSION',
  GET_ORGANISATION_SOURCES = 'GET_ORGANISATION_SOURCES',
  GET_SUGGESTIONS = 'GET_SUGGESTIONS',
  GET_UPDATE_AUTH_URL = 'GET_UPDATE_AUTH_URL',
  MAKE_PRESIGNED_URLS = 'MAKE_PRESIGNED_URLS',
  MIGRATE_O365_CONNECTORS = 'MIGRATE_O365_CONNECTORS',
  POST_MY_PROFILE = 'POST_MY_PROFILE',
  SEARCH = 'SEARCH',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  START_CONNECTOR_SYNC = 'START_CONNECTOR_SYNC',
  SUGGEST_QUERY = 'SUGGEST_QUERY',
  UPDATE_CONNECTOR = 'UPDATE_CONNECTOR',
  UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS',
}

/**
 * Retrieves the route maker for a certain query. We build the `apiUrl` from the possible `domainCode`
 * of the user. If the authenticated User does not have any `domainCode` it will default to `undefined`
 * which will then be replaced to `REACT_APP_API_URL` environment variable when building the final route.
 */
export const getRouteMaker = (
  query: ApiQuery,
  domainCode?: string,
): ((params?: Record<string, string | number>) => Route) => {
  const apiUrl = domainCode ? makeApiUrl(domainCode) : API_URL;

  const route = routes[query];

  if (!route) throw new Error(`Route for ${query} does not exist`);

  return (params?: Record<string, string | number>) => ({
    method: route.method,
    url: buildRoute(route.url, { ...params, apiUrl }),
  });
};

/**
 * Binds an ApiQuery to its related Route
 */
const routes: Record<ApiQuery, Route | null> = {
  BIND_CONNECTOR: {
    method: 'POST',
    url: ':apiUrl/connectors/:source/bind',
  },
  CREATE_CONNECTOR: {
    method: 'POST',
    url: ':apiUrl/connectors/:source',
  },
  DELETE_SOURCE: {
    method: 'DELETE',
    url: ':apiUrl/connectors/:source/:connectorId',
  },
  GET_AUTH_URL: {
    method: 'GET',
    url: ':apiUrl/sources/:source/auth-url',
  },
  GET_CONNECTOR: {
    method: 'GET',
    url: ':apiUrl/connectors/:source/:connectorId',
  },
  GET_CONNECTOR_ROOTS: {
    method: 'GET',
    url: ':apiUrl/connectors/:source/:connectorId/roots',
  },
  GET_CONNECTOR_SYNC_STATE: {
    method: 'GET',
    url: ':apiUrl/connectors/sync-states',
  },
  GET_FEATURE_FLAGS: {
    method: 'GET',
    url: ':apiUrl/feature-flags/:userId',
  },
  GET_FILE_PREVIEW: {
    method: 'GET',
    url: ':apiUrl/documents/files/:documentId/preview',
  },
  GET_LOCALDISK_CONNECTOR_STATE: {
    method: 'GET',
    url: ':apiUrl/connectors/localdisk/:connectorId/state',
  },
  GET_MAIL_THREADS_PREVIEW: {
    method: 'GET',
    url: ':apiUrl/documents/mails/:documentId/preview',
  },
  GET_MESSAGE_PREVIEW: {
    method: 'GET',
    url: ':apiUrl/documents/messages/:documentId/preview',
  },
  GET_MY_CONNECTORS: {
    method: 'GET',
    url: ':apiUrl/users/me/connectors',
  },
  GET_MY_PROFILE: {
    method: 'GET',
    url: ':apiUrl/users/me',
  },
  GET_NEW_PAYMENT_SESSION: {
    method: 'GET',
    url: ':apiUrl/payment/session',
  },
  GET_ORGANISATION_SOURCES: {
    method: 'GET',
    url: ':apiUrl/organisations/sources/:organisationId',
  },
  GET_SUGGESTIONS: {
    method: 'GET',
    url: ':apiUrl/search/suggest',
  },
  GET_UPDATE_AUTH_URL: {
    method: 'GET',
    url: ':apiUrl/sources/:source/update-auth-url/:connectorId',
  },
  MAKE_PRESIGNED_URLS: {
    method: 'POST',
    url: ':apiUrl/connectors/localdisk/urls',
  },
  MIGRATE_O365_CONNECTORS: {
    method: 'POST',
    url: ':apiUrl/connectors/o365/migrate',
  },
  POST_MY_PROFILE: {
    method: 'POST',
    url: ':apiUrl/users/me',
  },
  SEARCH: {
    method: 'GET',
    url: ':apiUrl/search',
  },
  SIGN_IN: {
    method: 'POST',
    url: ':apiUrl/signin/:provider',
  },
  SIGN_OUT: {
    method: 'POST',
    url: ':apiUrl/signout',
  },
  START_CONNECTOR_SYNC: {
    method: 'POST',
    url: ':apiUrl/connectors/:source/:connectorId/sync',
  },
  SUGGEST_QUERY: {
    method: 'GET',
    url: ':apiUrl/search/spelling-suggestions',
  },
  UPDATE_CONNECTOR: {
    method: 'PATCH',
    url: ':apiUrl/connectors/:source/:connectorId',
  },
  UPDATE_CREDENTIALS: {
    method: 'PATCH',
    url: ':apiUrl/connectors/:source/:connectorId/credentials',
  },
};

/**
 * Makes a `apiUrl` from a `domainCode`
 *
 * Ex: "tnp" => "https://api.tnp.outmind.fr"
 * Ex: "app", "ws" => "ws://api.app.outmind.fr"
 */
const makeApiUrl = (domainCode: string, protocol = 'https'): string =>
  `${protocol}://api.${domainCode}.outmind.fr`;

/**
 * Builds the route from the routeTemplate and the params
 */
const buildRoute = (routeTemplate: string, params?: Record<string, string | number>): string => {
  let route = routeTemplate;
  if (params) {
    Object.keys(params).forEach((paramKey) => {
      const httpParam = `:${paramKey}`;
      const paramRegExp = new RegExp(httpParam);
      if (!routeTemplate.match(paramRegExp) && !['apiWsUrl', 'apiUrl'].includes(paramKey)) {
        throw new Error(`UNKNOWN_PARAMETER: ${paramKey} (route: ${routeTemplate})`);
      }
      route = route.replace(paramRegExp, params[paramKey].toString());
    });
    return route;
  }
  return route;
};

/**
 * Describes an API route with its method and its url
 */
interface Route {
  method: Method;
  url: string;
}
