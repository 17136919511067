import { ConnectorSyncState, ConnectorSyncStatus } from '@outmind/types';
import React, { memo } from 'react';

import { useDuration, useTranslations } from '../../hooks';
import { CircularProgress, CircularProgressProps, Tooltip } from '../../material';
import { useStyles } from './styles';

/**
 * Displays a CircularProgress indicating the progression of the synchronization of a Connector
 */
const SyncProgressNP: React.FC<SyncProgressProps> = ({
  status,
  duration,
  progression: _progression,
  size: _size,
  ...rest
}) => {
  const size = typeof _size === 'string' ? parseInt(_size, 10) : _size ?? DEFAULT_PROGRESS_SIZE;
  const classes = useStyles({ size });

  const { t } = useTranslations();
  const makeDuration = useDuration();

  const progression = _progression ? (100 * Math.round(1000 * _progression)) / 1000 : 0;

  const title = `${progression}% ${t('synced_l')}`;
  if (duration) title.concat(` (~${makeDuration(duration)})`);

  const showProgress =
    status === ConnectorSyncStatus.InProgress || status === ConnectorSyncStatus.Starting;

  if (showProgress) {
    return progression > 0 ? (
      <Tooltip arrow placement="right" title={title}>
        <div>
          <div className={classes.circularProgressContainer}>
            <CircularProgress
              className={classes.circularProgress}
              {...rest}
              size={size}
              value={progression}
              variant="determinate"
            />
            <CircularProgress
              {...rest}
              classes={{ root: classes.circularProgress100 }}
              size={size}
              value={100}
              variant="determinate"
            />
          </div>
        </div>
      </Tooltip>
    ) : (
      <Tooltip arrow placement="right" title={t('synchronization_in_progress')}>
        <div className={classes.circularProgressContainer}>
          <CircularProgress {...rest} size={size} />
        </div>
      </Tooltip>
    );
  }

  return null;
};

const DEFAULT_PROGRESS_SIZE = 20;

interface SyncProgressProps
  extends CircularProgressProps,
    Omit<ConnectorSyncState, 'id' | 'connectorId'> {}

export const SyncProgress = memo(SyncProgressNP);
