import { sourcesProvideIndex } from '@outmind/helpers';
import React, { memo } from 'react';

import { indexOrder } from '../../../config';
import { useAllSearch, useConnectors, useSpellingSuggestionsIsFetching } from '../../../hooks';
import { NoResultsBackground } from '../../DocumentsListBackgrounds';
import { UpdateNotificationBanner } from '../../UpdateNotificationBanner';
import { HomeTabSection } from './HomeTabSection';
import { useStyles } from './styles';

const HomeTabNP: React.FC = () => {
  const classes = useStyles();

  const searchResponses = useAllSearch();

  const { data: connectors = [] } = useConnectors();

  const isSpellingSuggestionsFetching = useSpellingSuggestionsIsFetching();

  const sources = connectors.map((connector) => connector.source);

  const resultsSections = indexOrder.map((index) => {
    if (index === '*') return null;
    if (!sourcesProvideIndex(sources, index)) {
      return null;
    }
    return <HomeTabSection key={index} index={index} />;
  });

  const resultsFound = Object.values(searchResponses).reduce(
    (bool, res) => bool || res.total > 0,
    false,
  );

  const resultsIsFetching = Object.values(searchResponses).reduce(
    (isFetching, res) => isFetching || res.isFetching,
    false,
  );

  if (!resultsFound && !resultsIsFetching && !isSpellingSuggestionsFetching) {
    return <NoResultsBackground />;
  }

  return (
    <div className={classes.allTabContainer}>
      <UpdateNotificationBanner />
      {resultsSections}
    </div>
  );
};

export const HomeTab = memo(HomeTabNP);
