import {
  DocumentType,
  SearchFilesResult,
  SearchMailsResult,
  SearchPagesResult,
  SearchResult,
} from '@outmind/types';
import React from 'react';

import { File } from './File';
import { Mail } from './Mail';
import { Page } from './Page';

/**
 * Displays an ElasticDoc that can come from a search request or getting last elements
 */
export const Document: React.FC<DocumentProps> = ({
  bookmarkView,
  result,
  fromSearchPerson,
  index,
}) => {
  const { document } = result;

  if (document.type === DocumentType.Mail) {
    const mailResult = result as SearchMailsResult;
    return <Mail index={index} result={mailResult} />;
  }

  if (document.type === DocumentType.Page) {
    const pageResult = result as SearchPagesResult;
    return <Page index={index} result={pageResult} />;
  }
  const fileResult = result as SearchFilesResult;
  return (
    <File
      bookmarkView={bookmarkView}
      fromSearchPerson={fromSearchPerson}
      index={index}
      result={fileResult}
    />
  );
};

interface DocumentProps {
  bookmarkView?: boolean;
  fromSearchPerson?: boolean;
  index?: string;
  result: SearchResult;
}
