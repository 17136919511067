import React from 'react';

import { FullTree } from './FullTree';
import { SlicedTree } from './SlicedTree';

/**
 * Decides if and how the path must be sliced, depending on the
 * screen size.
 * @returns the arborescence to render
 */
export const Tree: React.FC<TreeProps> = ({ containerWidth, pathArr }) => {
  const fullPathWidth = getStringApproximateWidth(pathArr.join(' '), 'normal 12pt arial');
  if (containerWidth) {
    const longestPathWidth = Math.max(
      ...pathArr.map((_path) => getStringApproximateWidth(_path, 'normal 12pt arial')),
    );
    if (fullPathWidth < containerWidth) {
      return <FullTree pathArr={pathArr} />;
    }
    const pathSizeToShow = Math.round(containerWidth / longestPathWidth);

    return (
      <SlicedTree
        headSize={Math.floor(pathSizeToShow / 2)}
        pathArr={pathArr}
        tailSize={Math.ceil(pathSizeToShow / 2)}
      />
    );
  }

  return <FullTree pathArr={pathArr} />;
};

const CANVAS: HTMLCanvasElement = document.createElement('canvas');

const getStringApproximateWidth = (str: string, font = 'bold 13pt arial'): number => {
  const context = CANVAS.getContext('2d');
  if (context) {
    context.font = font;
    return context.measureText(str).width + (str.length < 4 ? 32 : 16);
  }
  return str.length * 8 + 16;
};

interface TreeProps {
  containerWidth?: number | null;
  pathArr: string[];
}
