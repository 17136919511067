import {
  ExtendedSources,
  FeatureFlags,
  Source,
  sources as sourcesDescription,
} from '@outmind/types';

import { initialFeatureFlags, useFeatureFlags } from '../useFeatureFlags';

export const useAvailableSources = (): Response$UseAvailableSources => {
  const { data: flags = initialFeatureFlags } = useFeatureFlags();

  const disabledSources: ExtendedSources[] = [];

  const sources = Object.values(Source).filter((source): boolean => {
    const sourceFlag = flags[sourcesDescription[source].flag];
    if (sourceFlag?.showAsDisabled && !sourceFlag?.enabled) disabledSources.push(source);

    return !!sourceFlag?.showAsDisabled || !!sourceFlag?.enabled;
  });

  if (!flags[FeatureFlags.withO365]?.enabled) disabledSources.push('o365');
  if (!flags[FeatureFlags.withGSuite]?.enabled) disabledSources.push('gsuite');

  const betaSources = [Source.LocalDisk, Source.NetworkDisk];

  return { betaSources, disabledSources, sources };
};

interface Response$UseAvailableSources {
  betaSources: Source[];
  disabledSources: ExtendedSources[];
  sources: Source[];
}
