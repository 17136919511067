import { DocumentType } from '@outmind/types';

import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { PreviewsState } from './types';

/**
 * Reduce incoming action for state.preview branch in Redux
 */
export const reducer = (state: PreviewsState = initialState, action: AppActions): PreviewsState => {
  switch (action.type) {
    case ActionTypes.OPEN_PREVIEW:
      return {
        ...state,
        isOpened: true,
      };
    case ActionTypes.SELECT_MAIL_PREVIEW: {
      if (action.payload === state.infos) return state;
      return {
        ...state,
        documentType: DocumentType.Mail,
        infos: action.payload,
      };
    }
    case ActionTypes.SELECT_FILE_PREVIEW: {
      if (action.payload === state.infos) return state;
      return {
        ...state,
        documentType: DocumentType.File,
        infos: action.payload,
      };
    }
    case ActionTypes.SELECT_SLIDE_PREVIEW: {
      if (action.payload === state.infos) return state;
      return {
        ...state,
        documentType: DocumentType.Page,
        infos: action.payload,
      };
    }
    case ActionTypes.CLOSE_PREVIEW:
      return {
        ...state,
        infos: undefined,
        isOpened: false,
      };
    default:
      return state;
  }
};

export const initialState: PreviewsState = {
  isOpened: false,
};
