/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DocumentIndicesExtended } from '@outmind/types';

import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

const selectedTabBaseStyle = {
  fontWeight: 500,
};

const selectedTabColor: Record<DocumentIndicesExtended, string> = {
  '*': '#245EE5',
  files: '#5B84E3',
  mails: '#E45D4D',
  pages: '#F29C38',
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      indexLabel: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
      },
      indexTabAllIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor['*'],
        },
        color: selectedTabColor['*'],
        fill: selectedTabColor['*'],
      },
      indexTabFileIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.files,
        },
        color: selectedTabColor.files,
        fill: selectedTabColor.files,
      },
      indexTabLabelContainer: {
        '&:not(.Mui-selected):hover': {
          color: theme.designSystem.colors.secondary.main,
          fill: theme.designSystem.colors.secondary.main,
        },
        flexBasis: 'auto',
        minWidth: 'unset',
      },
      indexTabMailIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.mails,
        },
        color: selectedTabColor.mails,
        fill: selectedTabColor.mails,
      },
      indexTabPageIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.pages,
        },
        color: selectedTabColor.pages,
        fill: selectedTabColor.pages,
      },
      indexTabRoot: {
        minHeight: 55,
      },
      indexTabWrapper: {
        '&>*:first-child': { marginBottom: '8px !important' },
        alignItems: 'center',
        color: 'inherit',
        display: 'flex',
        fill: 'inherit',
        flexDirection: 'row',
        fontSize: '14px',
        justifyContent: 'center',
        textTransform: 'capitalize',
        width: '100%',
      },
      searchTotalLabel: {
        marginLeft: '8px',
      },
      tabIndicator: { borderRadius: '26px', height: '2px' },
      tabs: {
        color: theme.designSystem.colors.aux.main,
        fill: theme.designSystem.colors.aux.main,
        width: '100%',
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    { index: 1 },
  )();
};
