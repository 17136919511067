import { Source } from '@outmind/types';
import React from 'react';

import { useTranslations } from '../../hooks';
import {
  Checkbox,
  CheckBoxOutlinedIcon,
  FormControlLabel,
  InfoIcon,
  Tooltip,
} from '../../material';
import { usePopper } from './popper';
import { useStyles } from './styles';

export const SyncDiscoveredRootsCheckBox: React.FC<SyncDiscoveredRootsCheckBoxrops> = ({
  setSyncDiscoveredRoots,
  syncDiscoveredRoots,
  sourceName,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const title = usePopper(sourceName);

  return (
    <div className={classes.syncDiscoveredContainer}>
      <FormControlLabel
        classes={{ label: classes.formControlLabel }}
        control={
          <Checkbox
            checked={syncDiscoveredRoots}
            checkedIcon={<CheckBoxOutlinedIcon />}
            classes={{ root: classes.checkBoxroot }}
            color="primary"
            name="checkedB"
            onChange={(e) => {
              setSyncDiscoveredRoots(e.target.checked);
            }}
          />
        }
        label={t('sync_discovered_items')}
      />
      <Tooltip
        classes={{ popper: classes.popper, tooltip: classes.tooltip }}
        placement="bottom"
        title={title}
      >
        <InfoIcon className={classes.infoIcon} />
      </Tooltip>
    </div>
  );
};

interface SyncDiscoveredRootsCheckBoxrops {
  setSyncDiscoveredRoots: React.Dispatch<React.SetStateAction<boolean>>;
  sourceName: Source;
  syncDiscoveredRoots: boolean;
}
