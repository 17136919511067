import { ExtendedSources, Source } from '@outmind/types';
import React from 'react';

import { AddLocalConnectorButton } from './AddLocalConnectorButton';
import { AddOAuthSourceButton } from './AddOAuthSourceButton';

export const AddSourceButton: React.FC<AddSourceButtonProps> = ({
  beta,
  disabled,
  setChosenSource,
  setShowAddSourceConfirmationDialog,
  source,
}) => {
  if (source === Source.LocalDisk || source === Source.NetworkDisk) {
    return <AddLocalConnectorButton beta={beta} disabled={disabled} source={source} />;
  }

  return (
    <AddOAuthSourceButton
      beta={beta}
      disabled={disabled}
      openAddSourceConfirmationDialog={() => {
        setChosenSource(source);
        setShowAddSourceConfirmationDialog(true);
      }}
      source={source}
    />
  );
};

interface AddSourceButtonProps {
  beta: boolean;
  disabled?: boolean;
  setChosenSource: (source: ExtendedSources) => void;
  setShowAddSourceConfirmationDialog: (bool: boolean) => void;
  source: Source;
}
