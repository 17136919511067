/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DocumentIndiceNames,
  ElasticDoc,
  ElasticFile,
  ElasticPerson,
  FileCategory,
  SearchByFieldOptions,
} from '@outmind/types';
import { action } from 'typesafe-actions';

export enum ActionTypes {
  ADD_DOCUMENT_RELATION_FILTER = 'ADD_DOCUMENT_RELATION_FILTER',
  ADD_FOLDER_FILTER = 'ADD_FOLDER_FILTER',
  ADD_PERSON_FILTER = 'ADD_PERSON_FILTER',
  REMOVE_DOCUMENT_RELATION_FILTER = 'REMOVE_DOCUMENT_RELATION_FILTER',
  REMOVE_FOLDER_FILTER = 'REMOVE_FOLDER_FILTER',
  REMOVE_PERSON_FILTER = 'REMOVE_PERSON_FILTER',
  SET_ACCOUNT_FILTER = 'SET_ACCOUNT_FILTER',
  SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER',
  SET_CHOSEN_INDEX_TAB = 'SET_CHOSEN_INDEX_TAB',
  SET_DATE_FILTER_FROM = 'SET_DATE_FILTER_FROM',
  SET_DATE_FILTER_TO = 'SET_DATE_FILTER_TO',
  SET_FIELD_FILTER = 'SET_FIELD_FILTER',
}

export const Actions = {
  addDocumentRelationFilter: (relatedDocument: {
    document: ElasticDoc;
    indexName: DocumentIndiceNames;
  }) => action(ActionTypes.ADD_DOCUMENT_RELATION_FILTER, relatedDocument),
  addFolderFilter: (folder: ElasticFile) => action(ActionTypes.ADD_FOLDER_FILTER, folder),
  addPersonFilter: (person: ElasticPerson) => action(ActionTypes.ADD_PERSON_FILTER, person),
  removeDocumentRelationFilter: () => action(ActionTypes.REMOVE_DOCUMENT_RELATION_FILTER),
  removeFolderFilter: () => action(ActionTypes.REMOVE_FOLDER_FILTER),
  removePersonFilter: () => action(ActionTypes.REMOVE_PERSON_FILTER),
  setAccountFilter: (connectorId: string, bool: boolean) =>
    action(ActionTypes.SET_ACCOUNT_FILTER, { bool, connectorId }),
  setCategoryFilter: (category: FileCategory, bool: boolean) =>
    action(ActionTypes.SET_CATEGORY_FILTER, { bool, category }),
  setDateFilterFrom: (date: Date | null) => action(ActionTypes.SET_DATE_FILTER_FROM, date),
  setDateFilterTo: (date: Date | null) => action(ActionTypes.SET_DATE_FILTER_TO, date),
  setFieldFilter: (field: SearchByFieldOptions, bool: boolean) =>
    action(ActionTypes.SET_FIELD_FILTER, { bool, field }),
};
