import React from 'react';
import { Route as PublicRoute, Switch } from 'react-router-dom';

import { AdminConsole } from '../AdminConsole';
import { AuthenticationProvider } from '../AuthenticationProvider';
import { DownloadDesktopAppView } from '../DownloadDesktopAppView';
import { GuardedRoute } from '../GuardedRoute';
import { MainView } from '../MainView';
import { PageNotFound } from '../PageNotFound';
import { RedirectRoute } from '../RedirectRoute';
import { RegisterView } from '../RegisterView';
import { Router } from '../Router';
import { SignInCallbackView, SignInClassicView, SignInStartView } from '../SignInViews';
import { SourcesRouter } from '../SourcesRouter';
import { SpotlightView } from '../SpotlightView';
import { VIPAccessView } from '../VIPAccessView';

/**
 * Renders the whole Outmind app
 */
export const App: React.FC = () => (
  <AuthenticationProvider>
    <Router>
      <Switch>
        <GuardedRoute component={MainView} exact path="/" />

        <GuardedRoute component={AdminConsole} path="/admin-console" />

        <PublicRoute component={DownloadDesktopAppView} path="/download-desktop" />

        <GuardedRoute component={RegisterView} exact path="/register" scope="guest" />
        <GuardedRoute component={VIPAccessView} path="/register/vip" scope="guest" />

        <GuardedRoute component={MainView} path="/search" />

        <RedirectRoute component={SignInClassicView} exact path="/signin" />
        <PublicRoute component={SignInStartView} path="/signin/start" />
        <PublicRoute component={SignInCallbackView} path="/signin/callback" />

        <GuardedRoute component={SourcesRouter} path="/sources" />

        <PublicRoute component={SpotlightView} path="/spotlight" />

        <PublicRoute component={PageNotFound} />
      </Switch>
    </Router>
  </AuthenticationProvider>
);
