import React from 'react';

import { useRuntimeEnv, useTranslations } from '../../hooks';
import {
  GoToIcon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PictureInPictureAltIcon,
  SearchIcon,
  VisibilityOutlinedIcon,
} from '../../material';
import { useStyles } from './styles';

export const ContextMenu: React.FC<ContextMenuProps> = ({
  mouseX,
  mouseY,
  onClose,
  onFolderSearch,
  onOpen,
  onOpenInFileExplorer,
  onPreview,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const { inMacOS } = useRuntimeEnv();

  return (
    <Menu
      anchorPosition={mouseY && mouseX ? { left: mouseX, top: mouseY } : undefined}
      anchorReference="anchorPosition"
      classes={{ paper: classes.styleMenuPaper }}
      keepMounted
      onClose={onClose}
      open={mouseY !== null}
    >
      {onFolderSearch ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="search-in-folder-menu-item"
          onClick={() => {
            onClose();
            onFolderSearch();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <SearchIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText classes={{ root: classes.itemText }} primary={t('search_in_folder')} />
        </MenuItem>
      ) : null}
      {onPreview ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="open-preview-menu-item"
          onClick={() => {
            onClose();
            onPreview();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <VisibilityOutlinedIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText classes={{ root: classes.itemText }} primary={t('preview')} />
        </MenuItem>
      ) : null}

      <MenuItem
        classes={{ root: classes.menuItem }}
        className="open-in-source-menu-item"
        onClick={() => {
          onClose();
          onOpen();
        }}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <GoToIcon classes={{ root: classes.itemIcon }} />
        </ListItemIcon>
        <ListItemText classes={{ root: classes.itemText }} primary={t('open_in_source')} />
      </MenuItem>

      {onOpenInFileExplorer ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="open-in-finder-menu-item"
          onClick={() => {
            onClose();
            onOpenInFileExplorer();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <PictureInPictureAltIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.itemText }}
            primary={t(inMacOS ? 'show_in_finder' : 'open_with_file_explorer')}
          />
        </MenuItem>
      ) : null}

      <div className={classes.separator} />
    </Menu>
  );
};

interface ContextMenuProps {
  bookmarked?: boolean;
  documentId: string;
  mouseX: number | null;
  mouseY: number | null;
  onClose: () => void;
  onFolderSearch?: () => void;
  onOpen: () => void;
  onOpenInFileExplorer?: () => void;
  onPreview?: () => void;
}
