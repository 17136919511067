import { ElasticFile, ElasticPerson, SearchSuggestionsResult } from '@outmind/types';
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';

import { useConnectors, useRuntimeEnv, useSpellingSuggestions, useSuggestions } from '../../hooks';
import { Actions, useDispatch, useSelector } from '../../store';
import { SearchInput } from './SearchInput';
import { SearchSuggestions } from './SearchSuggestions';
import { SearchBarStylingProps, useStyles } from './styles';

/**
 * SearchBar renders the generic Outmind searchbar
 */
const SearchBarNP = forwardRef<HTMLDivElement, SearchBarProps>(
  ({ autoFocus, fullWidth, hasBigHeight, inSpotlight, isSharp }, inputRef) => {
    const {
      folder: folderFilter,
      person: personFilter,
      relatedDocuments: relatedDocumentsFilter,
    } = useSelector((s) => s.filters);
    const q = useSelector((s) => s.search.userQ);
    const [cursor, setCursor] = useState(-1);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [shouldSuggestSpellingCorrection, setShouldSuggestSpellingCorrection] = useState(false);

    useSpellingSuggestions(shouldSuggestSpellingCorrection);

    const { data: connectors = [] } = useConnectors();

    const dispatch = useDispatch();

    const { inElectron } = useRuntimeEnv();

    const barRef = useRef<HTMLDivElement>(null);

    const [searchBarQ, setSearchBarQ] = useState(q);
    const [suggestionsQ, setSuggestionsQ] = useState(q);

    const connectorIds = connectors.map((connector) => connector.id);

    const { data: suggestions = [], isFetching: suggestionsLoading } = useSuggestions({
      connectorIds,
      q: suggestionsQ,
    });

    const classes = useStyles({
      fullWidth,
      hasBigHeight,
      inSpotlight,
      isSharp,
      withSuggestions: suggestionsActive && (suggestions.length > 0 || !suggestionsLoading),
    });

    const selectedSuggestion = suggestions[cursor];

    useEffect(() => {
      setSearchBarQ(q);
      setSuggestionsQ(q);
      setShouldSuggestSpellingCorrection(false);
    }, [q, folderFilter, personFilter, relatedDocumentsFilter]);

    const triggerSuggestion = useCallback(
      (suggestion: SearchSuggestionsResult): void => {
        const {
          action: { type: actionType, value: actionValue },
          document,
        } = suggestion.document;
        if (actionType === 'filter:byPerson') {
          dispatch(Actions.setSearchUserQuery(''));
          dispatch(Actions.addPersonFilter(document as ElasticPerson));
        } else if (actionType === 'filter:inFolder') {
          dispatch(Actions.setSearchUserQuery(''));
          dispatch(Actions.addFolderFilter(document as ElasticFile));
        } else if (actionType === 'goto') {
          if (inElectron) {
            window.electron?.openWithDefaultHandler(actionValue);
          } else {
            window.open(actionValue, '_blank');
          }
        } else if (actionType === 'query') {
          dispatch(Actions.setSearchUserQuery(actionValue));
          dispatch(Actions.setSearchQuery(actionValue));
          dispatch(Actions.refreshSearch());
        }
      },
      [dispatch, inElectron],
    );

    const reset = useCallback(() => {
      setSearchBarQ('');
      setSuggestionsQ('');
      setSuggestionsActive(false);
      setCursor(-1);
    }, [setSearchBarQ, setSuggestionsQ, setSuggestionsActive, setCursor]);

    const startSearch = useCallback(
      (suggestion?: SearchSuggestionsResult, query: string = searchBarQ): void => {
        if (!(suggestion || selectedSuggestion)) {
          setShouldSuggestSpellingCorrection(true);
          dispatch(Actions.setSearchUserQuery(query));
          dispatch(Actions.setSearchQuery(query));
          dispatch(Actions.refreshSearch());
        } else {
          triggerSuggestion(suggestion || selectedSuggestion);
        }
        setSuggestionsActive(false);
        setCursor(-1);
      },
      [dispatch, searchBarQ, selectedSuggestion, triggerSuggestion, cursor, reset],
    );

    const onUpdate = useCallback(
      (value: string): void => {
        setSearchBarQ(value);
        setSuggestionsQ(value);
        setCursor(-1);
      },
      [setSearchBarQ, setSuggestionsQ],
    );

    return (
      <div className={classes.searchBarContainer}>
        <SearchInput
          ref={inputRef}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          hasBigHeight={hasBigHeight}
          inSpotlight={inSpotlight}
          isSharp={isSharp}
          onUpdate={onUpdate}
          q={q}
          reset={reset}
          setSuggestionsActive={setSuggestionsActive}
          startSearch={startSearch}
          suggestionsActive={suggestionsActive}
          withSuggestions={suggestionsActive && (suggestions.length > 0 || !suggestionsLoading)}
        />
        <SearchSuggestions
          barRef={barRef}
          cursor={cursor}
          decrementCursor={() => {
            setCursor((c) => (c > 0 ? c - 1 : suggestions.length - 1));
          }}
          hideSuggestions={() => {
            setSuggestionsActive(false);
            setCursor(-1);
          }}
          incrementCursor={() => {
            setCursor((c) => (c < suggestions.length - 1 ? cursor + 1 : 0));
          }}
          inSpotlight={inSpotlight}
          isActive={suggestionsActive}
          isLoading={suggestionsLoading}
          isSharp={isSharp}
          onClickSuggestion={startSearch}
          suggestions={suggestions}
        />
      </div>
    );
  },
);

interface SearchBarProps extends Omit<SearchBarStylingProps, 'withSuggestions'> {
  autoFocus?: boolean;
}

export const SearchBar = memo(SearchBarNP);
