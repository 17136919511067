import { KeyHandler } from '@outmind/helpers';
import { SearchSuggestionsResult } from '@outmind/types';
import React, { memo, useEffect, useRef } from 'react';

import { useTranslations } from '../../../hooks';
import { List, Paper } from '../../../material';
import { SearchSuggestion } from './SearchSuggestion';
import { useStyles } from './styles';

const SearchSuggestionsNP: React.FC<SearchSuggestionsProps> = ({
  barRef,
  cursor,
  decrementCursor,
  hideSuggestions,
  incrementCursor,
  isActive,
  inSpotlight,
  isLoading,
  isSharp,
  onClickSuggestion,
  suggestions,
}) => {
  const classes = useStyles({ inSpotlight, isSharp });

  const { t } = useTranslations();

  const barContainerRef = useRef<HTMLDivElement>();

  const downIsPressed = KeyHandler.useKeyDown('ArrowDown');
  const upIsPressed = KeyHandler.useKeyDown('ArrowUp');

  useEffect(() => {
    const handleClickOut = (e: MouseEvent): void => {
      if (barContainerRef && barContainerRef.current) {
        if (
          barContainerRef.current?.contains(e.target as Node) ||
          barRef?.current?.contains(e.target as Node)
        ) {
          return;
        }
        hideSuggestions();
      }
    };
    document.addEventListener('click', handleClickOut);

    return () => {
      document.removeEventListener('click', handleClickOut);
    };
  }, [barContainerRef, barRef, hideSuggestions]);

  useEffect(() => {
    if (isActive && suggestions.length) {
      if (downIsPressed) {
        incrementCursor();
      } else if (upIsPressed) {
        decrementCursor();
      }
    }
  }, [downIsPressed, upIsPressed]);

  const searchSuggestions = suggestions.map((suggestion, index) => (
    <SearchSuggestion
      key={suggestion.document.id}
      index={index}
      inSpotlight={inSpotlight}
      isSelected={index === cursor}
      onClick={() => onClickSuggestion(suggestion)}
      suggestion={suggestion}
    />
  ));

  if (isActive && suggestions.length) {
    return (
      <Paper
        ref={barContainerRef}
        className={classes.suggestionsContainer}
        elevation={3}
        id="suggestions"
      >
        <List component="nav">{searchSuggestions}</List>
      </Paper>
    );
  }

  if (isActive && !suggestions.length && !isLoading) {
    return (
      <Paper ref={barContainerRef} className={classes.suggestionsContainer} elevation={3}>
        <List component="nav">
          <i className={classes.noSuggestion}>{t('no_suggestions_helper')}</i>
        </List>
      </Paper>
    );
  }

  return null;
};

interface SearchSuggestionsProps {
  barRef?: React.RefObject<HTMLDivElement>;
  cursor: number;
  decrementCursor: () => void;
  hideSuggestions: () => void;
  inSpotlight?: boolean;
  incrementCursor: () => void;
  isActive: boolean;
  isLoading?: boolean;
  isSharp?: boolean;
  onClickSuggestion: (suggestion: SearchSuggestionsResult) => void;
  suggestions: SearchSuggestionsResult[];
}

export const SearchSuggestions = memo(SearchSuggestionsNP);
