import { DocumentIndiceNames } from '@outmind/types';
import React, { memo } from 'react';

import {
  useDisplayedResultsNumber,
  useSearch,
  useSpellingSuggestionsIsFetching,
  useTranslations,
} from '../../../../hooks';
import { TranslationKey } from '../../../../locales/types';
import { Grid } from '../../../../material';
import { Actions, useDispatch } from '../../../../store';
import { Document } from '../../../Document';
import { DocumentSkeleton } from '../../../DocumentSkeleton';
import { IndexIcon } from '../../../IndexIcon';
import { LabeledBox } from '../../../LabeledBox';

const HomeTabSectionNP: React.FC<AllTabResultsSectionProps> = ({ index }) => {
  const dispatch = useDispatch();

  const displayedResultsNumber = useDisplayedResultsNumber(index);

  const isSpellingSuggestionsFetching = useSpellingSuggestionsIsFetching();

  const { results, isLoading, total } = useSearch(index);

  const { t } = useTranslations();

  const slicedSectionResults = results.slice(0, displayedResultsNumber);
  const sectionResultElements = slicedSectionResults.map((result, idx) => (
    <Document key={result.document.id} index={`home-${idx + 1}`} result={result} />
  ));

  const contentContainer = ['contacts', 'people', 'pages'].includes(index) ? (
    <Grid container style={{ padding: '8px 16px' }}>
      {sectionResultElements}
    </Grid>
  ) : (
    sectionResultElements
  );

  const resultsCount = t('results_count', { total: total >= 10000 ? '10k+' : total });
  const resultsCountPlural = t('results_count_plural', { total: total >= 10000 ? '10k+' : total });
  const sectionTitle = `${t(`index_name_${index}` as TranslationKey)} ${
    total <= 1 ? resultsCount : resultsCountPlural
  }`;

  const seeAll = (): void => {
    dispatch(Actions.setChosenIndexTab(index));
  };

  if (isLoading || isSpellingSuggestionsFetching) {
    return (
      <LabeledBox
        icon={<IndexIcon index={index} type="home" />}
        seeAll={seeAll}
        title={sectionTitle}
      >
        <Grid container={index === 'pages'}>
          {[...Array(displayedResultsNumber)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DocumentSkeleton key={i} index={index} />
          ))}
        </Grid>
      </LabeledBox>
    );
  }

  if (sectionResultElements.length) {
    return (
      <LabeledBox
        icon={<IndexIcon index={index} type="home" />}
        seeAll={seeAll}
        title={sectionTitle}
      >
        {contentContainer}
      </LabeledBox>
    );
  }

  return null;
};

interface AllTabResultsSectionProps {
  index: DocumentIndiceNames;
}

export const HomeTabSection = memo(HomeTabSectionNP);
