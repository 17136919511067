import { SearchSuggestionsResult } from '@outmind/types';
import { Search } from '@styled-icons/fluentui-system-filled';
import { Dismiss } from '@styled-icons/fluentui-system-regular/Dismiss';
import clsn from 'classnames';
import _ from 'lodash';
import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';

import { useTranslations } from '../../hooks';
import { IconButton, InputAdornment, InputBase, Paper } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { FolderFilterChip, PersonFilterChip, RelatedDocumentFilterChip } from '..';
import { SearchBarStylingProps, useStyles } from './styles';

/**
 * SearchInput renders the generic Outmind searchbar
 */
const SearchInputNP = forwardRef<HTMLDivElement, SearchInputProps>(
  (
    {
      onUpdate,
      q = '',
      autoFocus,
      fullWidth,
      hasBigHeight,
      inSpotlight,
      isSharp,
      reset,
      startSearch,
      setSuggestionsActive,
      suggestionsActive,
      withSuggestions,
    },
    inputRef,
  ) => {
    const {
      folder: folderFilter,
      person: personFilter,
      relatedDocuments: relatedDocumentsFilter,
    } = useSelector((s) => s.filters);

    const dispatch = useDispatch();

    const barRef = useRef<HTMLDivElement>(null);

    const [searchBarQ, setSearchInputQ] = useState(q);
    const [searchBarIsActive, setSearchInputIsActive] = useState(false);
    const [searchBarIsFocused, setSearchInputIsFocused] = useState(false);

    const updateQuery = useCallback(_.debounce(onUpdate, 150), []);

    const classes = useStyles({
      fullWidth,
      hasBigHeight,
      inSpotlight,
      isSharp,
      withSuggestions,
    });

    const { t } = useTranslations();

    useEffect(() => {
      setSearchInputQ(q);
    }, [q]);

    const onBlur = (): void => {
      setSearchInputIsFocused(false);
      dispatch(Actions.setSearchUserQuery(searchBarQ));
    };

    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchInputQ(e.currentTarget.value);
        updateQuery(e.currentTarget.value);
        setSuggestionsActive(true);
      },
      [setSearchInputQ],
    );

    const resetQuery = useCallback((): void => {
      setSearchInputQ('');
      reset();
    }, [setSearchInputQ]);

    return (
      <Paper
        className={clsn(
          classes.searchBarPaper,
          { [classes.searchBarActive]: searchBarIsActive },
          { [classes.searchBarFocus]: suggestionsActive },
        )}
        elevation={inSpotlight || suggestionsActive ? 3 : 0}
        onBlur={onBlur}
        onFocus={() => setSearchInputIsFocused(true)}
        onMouseEnter={() => setSearchInputIsActive(true)}
        onMouseLeave={() => setSearchInputIsActive(false)}
      >
        <InputBase
          ref={barRef}
          autoComplete="off"
          autoFocus={autoFocus}
          className={classes.searchBar}
          endAdornment={
            searchBarQ ? (
              <IconButton
                aria-label="search"
                classes={{ root: classes.iconButton }}
                onClick={resetQuery}
              >
                <Dismiss size="20" title="clear" />
              </IconButton>
            ) : null
          }
          inputRef={inputRef}
          name="q"
          onChange={onChange}
          onClick={() => setSuggestionsActive(true)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') startSearch(undefined, searchBarQ);
          }}
          placeholder={folderFilter || personFilter ? '' : t('search')}
          startAdornment={
            <InputAdornment className={classes.searchBarAdornment} position="start">
              <IconButton
                aria-label="search"
                classes={{ root: classes.iconButton }}
                id="search-button"
                onClick={(e) => {
                  e.stopPropagation();
                  startSearch(undefined, searchBarQ);
                }}
              >
                <Search size="24" title="search" />
              </IconButton>
              {folderFilter ? (
                <div className={classes.chipContainer}>
                  <FolderFilterChip
                    folder={folderFilter}
                    isFocused={searchBarIsFocused}
                    isHovered={searchBarIsActive}
                    onDelete={() => dispatch(Actions.removeFolderFilter())}
                  />
                </div>
              ) : null}
              {personFilter ? (
                <div className={classes.chipContainer}>
                  <PersonFilterChip
                    isFocused={searchBarIsFocused}
                    isHovered={searchBarIsActive}
                    onDelete={() => dispatch(Actions.removePersonFilter())}
                    person={personFilter}
                  />
                </div>
              ) : null}
              {relatedDocumentsFilter
                ? relatedDocumentsFilter.map((filter) => (
                    <div className={classes.chipContainer}>
                      <RelatedDocumentFilterChip
                        document={filter.document}
                        onDelete={() => dispatch(Actions.removeDocumentRelationFilter())}
                      />
                    </div>
                  ))
                : null}
            </InputAdornment>
          }
          value={searchBarQ}
        />
      </Paper>
    );
  },
);

interface SearchInputProps extends SearchBarStylingProps {
  autoFocus?: boolean;
  onUpdate: (value: string) => void;
  q?: string;
  reset: () => void;
  setSuggestionsActive: (bool: boolean) => void;
  startSearch: (_suggestion?: SearchSuggestionsResult, _query?: string) => void;
  suggestionsActive: boolean;
}

export const SearchInput = memo(SearchInputNP);
