import { ConnectorSyncState, ConnectorSyncStatus, Source } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for starting the synchronization of a connector
 */
export const useStartSync = (): UseMutationResult<
  unknown,
  unknown,
  Params$UseStartSync,
  unknown
> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.START_CONNECTOR_SYNC);

  const startSyncMutation = useMutation<unknown, unknown, Params$UseStartSync>(
    async ({ connectorId, source }) => {
      const route = makeRoute({ connectorId, source });

      await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onSuccess: (_, { connectorId }) => {
        const states = queryClient.getQueryData<Record<string, ConnectorSyncState>>([
          ApiQuery.GET_CONNECTOR_SYNC_STATE,
        ]);
        queryClient.setQueryData([ApiQuery.GET_CONNECTOR_SYNC_STATE], {
          ...states,
          [connectorId]: {
            ...states?.[connectorId],
            status: ConnectorSyncStatus.Starting,
          },
        });
      },
    },
  );

  return startSyncMutation;
};

interface Params$UseStartSync {
  connectorId: string;
  source: Source;
}
